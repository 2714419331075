@import "src/theme/variables.scss";

:host {
	height: 100%;
    z-index: 1;
}

._book-training-mobile-page {
    padding-left: 10px;
	padding-right: 10px;
    height: calc(100% - 30px);
    display: flex;
    flex-direction: column;

    ._progress-indicator {
        margin-top: 10px;
		background-color: white;
		box-shadow: $box-shadow-light;
		border-radius: $border-radius;
		margin-bottom: 10px;
		display: flex;
		flex-direction: column;

        progress-indicator-mobile-component ::ng-deep {
            ._progress-indicator-mobile-component {
                padding: 20px;

                ._progress-step:not(._inProgress) {
                    background-color: lightgrey;
                }

            }
        }
        
	}

    ._container {
        background-color: white;
		box-shadow: $box-shadow-light;
		border-radius: $border-radius;
        flex-grow: 1;
		height: calc(100% - 200px);
        
        ._action-container {
			padding: 20px;
            height: 100%;
			overflow-y: scroll;
			

			._intro {
				margin-bottom: 30px;
			}

			._container-subtitle {
				font-size: 14px;
				margin-bottom: 3px;
			}

            &._type {

				._types-container {
					display: flex;
					flex-direction: row;
					align-items: stretch;
					justify-content: space-between;

					._type-container {
						width: 45%;
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: center;
						padding: 20px;
						background-color: #fdfdfd;
    					border: 1px solid #E0E0E0;
						border-radius: $border-radius;

						&._selected {
							background-color: #F1F1F1;
						}

						&:hover {
							cursor: pointer;
						}

						._subtitle {
							margin-top: 10px;
							text-align: center;
						}

						ion-icon {
							font-size: 60px;
							margin-bottom: 20px;
						}

					}

				}

				._extra-container {
					margin-top: 30px;

					._template-and-language {

						form-select,
						form-select-training-template {
							display: block;
                            &:not(:first-child) {
                                margin-top: 20px;

                            }
						}

					}

					._small-title {
						margin-top: 30px;
					}

					form-full-address {
						display: block;
						margin: 10px -10px 0 -10px;
					}

				}


			}

            &._trainings {

				._intro {
					margin-bottom: 20px;
				}
				
				/* FOR CALENDAR */

				._calendar-events {
					display: flex;
					flex-direction: row;
					align-items: center;
					flex-wrap: wrap;
                    gap: 10px;
                    margin-bottom: 10px;

					._calendar-event {
						display: flex;
						flex-direction: row;
						align-items: center;
						border-radius: 10px;
						border: 1px solid $green;
						padding: 5px 10px;

						&._selected {
							border: 1px solid #e1e1e1;
							background-color: #85C7F2;

							p {
								color: black !important;
							}

						}

						._event {
							display: flex;
							flex-direction: row;
							align-items: center; 

							ion-icon {
								margin-left: 10px;

								&:hover {
									cursor: pointer;
								}

							}
						}

						._event-prefix {
							margin-right: 5px;
							font-weight: bolder;
						}

						p {
							margin: 0;
							font-size: 13px;
							display: inline;

							&._no-event,
							&._event-prefix {
								color: grey;
							}

						}	

					}

				}

				._calendar-filters {
					margin-bottom: 10px;
					display: flex;
					flex-direction: row;
			
					._filter-name {
						color: grey;
						margin: 5px 0;
						text-transform: uppercase;
					}
					
				}
			
				._calendar {
					height: 100%;

					mwl-calendar-month-view {
			
						.cal-month-view {
							border-radius: $border-radius;
							border: 1px solid #e1e1e1;
                            box-shadow: none;
						}
			
					}
			
					mwl-calendar-week-view {
			
						.cal-week-view {
							border-radius: $border-radius;
							border: 1px solid #e1e1e1;
							overflow-y: hidden;
                            box-shadow: none;
						}
			
					}
			
				}

                /* FOR LIST */

                date-filter-component,
                select-filter-component,
                input-filter-component {
                    margin: 20px 10px;
                    display: block;
                    width: calc(100% - 20px);
                    height: 45px;

                    ::ng-deep {

                        ._data-filter-component {
                            width: 100%;

                            input {
                                width: 100% !important;
                            }

                            ::ng-deep {
                                
                                ._selected {
                                    width: 100%;

                                    ._date-range {
                                        justify-content: right;
                                    }
                                }

                            }

                            
                        }

                    }
                }

				data-display-mobile-component {
					height: calc(100% - 90px);
					display: block;
				}

                data-display-mobile-component ::ng-deep {
                    ._filter-bar {
                        margin: 0;
                        margin-bottom: 10px;
                    }

                    ._filter-order-container {
                        margin: 0;
                        width: 100%;
                    }
                }

                ion-card {
                    margin: 0;
                    margin-top: 10px;
                    border: 1px solid #D3D3D3;
                    box-shadow: none;
                    border-radius: 15px;

                    &._selected {
                        background-color: #F1F1F1;
                    }

                    ion-card-content {
                        padding: 0;
    
                        ._name {
                            font-weight: 450;
                            font-size: 20px;
                            color: black;
                            text-align: center;
                            padding-bottom: 20px;
                        }
    
                        ._info {
                            width: 100%;
                            padding: 20px 20px 0 20px;
                            border-bottom: 1px solid #F5F5F5;
    
                            p {
                                word-break: break-word;
                            }
                        }
    
                        ._buttons {
                            padding: 20px;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: space-evenly;
    
                            ._button {
                                padding: 10px;
    
                                span {
                                    margin-right: 10px;
                                }
                            }
    
                            ._navigate-button {
                                background-color: $orange;
                            }
    
                            ._info-button {
                                background-color: $green;
                            }
    
                        }
    
                    }
                }

			}

            &._trainees {

				._trainees-info {

					form-number,
					form-textarea {
						display: block;
						margin-bottom: 20px;
					}

				}

				._contact-container {

					._contact-info {
						margin-top: 20px;
						display: flex;
						flex-direction: row;
						justify-content: space-between;
						flex-wrap: wrap;
					}
	
					form-input {
						display: block;
						width: 49%;
						margin-bottom: 20px;
					}
	
				}

				._invoicing-details-container {
					margin-top: 20px;
                    margin-bottom: 20px;
				}

			}

			&._terms-and-conditions {

				._terms-and-conditions-container {
					display: flex;
					flex-direction: row;
					align-items: center;
					margin-top: 20px;
					margin-bottom: 10px;
					margin-left: 10px;
					flex-shrink: 0;
		
					ion-label {
						margin-left: 10px;
						flex-shrink: 10;
					}
		
				}
			}

			&._confirmation {

				._confirmation-container {
					margin: 30px 0;

					._map-container {
						width: 100%;
						height: 250px;
						border: 1px solid #D3D3D3;
						border-radius: $border-radius;
						overflow: hidden;
						margin-bottom: 20px;
					}

				}

				._booking-disclaimer-container {
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					margin-top: 10px;

					._booking-disclaimer {
						display: flex;
						flex-direction: row;
						align-items: center;

						ion-icon {
							color: $orange;
							font-size: 30px;
							margin-right: 10px;
						}
	
						p {
							font-size: 20px;
							margin: 0;
							flex-shrink: 10;
						}
					}

					._finish {
						margin-top: 20px;
						background-color: $green;
						font-weight: bold;

						span {
							font-size: 20px;
						}
					}

				}

				._booked-confirmation-container {
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;

					ion-icon {
						color: $green;
						font-size: 200px;
					}

					p {
						font-size: 25px;
					}

				}

			}
        }
    }

    ._buttons-container {
        background-color: white;
		box-shadow: $box-shadow-light;
		border-radius: $border-radius;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        padding: 20px;
        margin-top: 10px;
	
        ._button {
            margin: 0 5px;

            &._previous {
                background-color: $orange;
            }  

            &._cancel {
                background-color: $red;
                color: white;
                span {
                    color: white;
                }
            }  

            &._next {
                background-color: $green;
            } 

            &._finish {
                background-color: $green;
                font-weight: bold;
            } 

            span {
                display: block;
                text-align: center;
                width: 100%;
                margin: 0;
            }

        } 
    }

}