@import "src/theme/variables.scss";

.input {

    .label {
        margin: 0;
        margin-bottom: 3px;
        color: gray;
        text-align: left;
        font-size: 14px;

        span {
            color: #EB4B24 !important;
			font-weight: bold;
        }
    }

    ._date-time-picker-inner-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        input:focus {
            
        }

        ._time-picker {
            display: flex;
            flex-direction: column;
        }

        ._time-range-line {
            width: 10px;
            height: 2px;
            background-color: black;
        }

        .owl-dt-inline-container {
            box-shadow: none;
            width: auto;
			background-color: transparent;

            .owl-dt-timer {
                padding: 0;
                height: auto;

                .owl-dt-timer-box {
                    width: auto;
                    height: auto;
        
                    &:first-of-type {
                        margin-right: 5px;
                    }
                    &:last-of-type {
                        margin-left: 5px;
                    }

                    .owl-dt-timer-divider {
                        left: -9px;

                        &::before,
                        &::after {
                            width: 2px;
                            height: 2px;
                            border-radius: 100%;
                        }

                        &::before {
                            top: 45%;
                        }

                        &::after {
                            bottom: 45%;
                        }

                    }
                    
                    .owl-dt-timer-content {
                    
                        input {
                            font-size: 13px;
                        }
        
                    }
        
                }

            }

        }

    }
}