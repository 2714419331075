@import "src/theme/variables.scss";

._base-calendar-mobile-component {
	padding-bottom: 0;

	._current-daterange {
		text-align: center;
		width: 100%;
		padding: 10px;
		background-color: white;
		border-radius: $border-radius;
		border: 1px solid #D3D3D3;
		margin-top: 0;
		margin-bottom: 5px;
    }

    ._calendar-headers {
        display: flex;
        flex-direction: row;
        align-items: center;

        ._navigation-buttons {
            display: flex;
            flex-direction: row;
            align-items: center;
            background-color: white;
            border: 1px solid #D3D3D3;
            border-radius: 12px;
            height: fit-content;
            margin-right: 10px;

            ._navigation-button {
                padding: 5px 20px;

                &:hover {
                    cursor: pointer;
                }

                ion-icon {
                    font-size: 20px;
                    font-weight: bold;
                    margin-top: 5px;
                }

            }

            ._today-navigation-button {
                text-align: center;
                border-right: 1px solid #D3D3D3;
                border-left: 1px solid #D3D3D3;

                ion-icon {
                    font-size: 12px;
                }

                p {
                    margin: 0;
                    font-size: 7px;
                }
            }

        }
        
        ._view-selection {
            background-color: white;
            border: 1px solid #D3D3D3;
            border-radius: 12px;
            margin-right: 10px;

            ion-select {
                padding: 9px 20px;
            }
        }
		
		._fab {
			margin-right: 20px;
			margin-bottom: 20px;
		}

		._refresh-button {
			background-color: $orange;
		}

    }

    ._calendar {
        margin-top: 10px;
        height: calc(100vh - 245px);

        ._event-icon {
            width: 15px;
            height: 15px;
            float: right;
            margin-top: 5px;
            margin-left: 5px;
            color: black;
        }

        mwl-calendar-month-view {

            .cal-month-view {
                border-radius: $border-radius;
                box-shadow: $box-shadow-light;
                // height: 100%;
				height: calc(100% - env(safe-area-inset-top) - env(safe-area-inset-bottom));
                overflow-y: auto;

                .cal-header .cal-cell {
                    padding-top: 15px;
                    padding-bottom: 15px;
                }

                .cal-days {
                    border-left: none;
                    border-right: none;
                    border-bottom: none;

                    .ng-star-inserted:last-child {

                        .cal-cell-row {
                            border-bottom: none;
                        }

                    }

                } 

                .cal-day-badge {
                    display: none;
                }

            }

        }

        mwl-calendar-week-view {

            .cal-week-view {
                border-radius: $border-radius;
                box-shadow: $box-shadow-light;
                border: none;
				// height: 100%;
				height: calc(100% - env(safe-area-inset-top) - env(safe-area-inset-bottom));
                overflow-y: auto;

                .cal-day-headers {
                    border-left: none;
                    border-right: none;
                    padding-left: 0;
                    margin-left: 50px;
    
                    .cal-header {
                        border-left: none;
                        border-right: none;
                        padding-top: 10px;
                        padding-bottom: 10px;

                        &:hover {
                            cursor: pointer;
                            background-color: #ededed !important;
                        }

                        &:focus {
                            
                        }
                    }
                    .cal-today {
                        position: relative;
                        background-color: transparent;
                        color: $orange;
                    }
                    .cal-today::before {
                        content: "•";
                        color: $orange;
                        font-size: 25px;
                        position: absolute;
                        top: -7px;
                        left: 50%;
                        transform: translate(-50%, 0);
                    }
    
                }

				.cal-all-day-events {
					border-left: none;
					border-bottom-width: 1px;

					.cal-events-row {
						margin-left: 50px;
					}

					.cal-time-label-column {
                        width: 50px;

                        .cal-time {
                            width: auto;
                        }

                    }
	
					.cal-event {
						color: black;
					}
	
					& + .cal-time-events {
						height: calc(100% - 98px);
					}
	
				}

                .cal-time-events {
                    border: none;
                    height: calc(100% - 63px);
                    overflow-y: auto;

                    .cal-day-columns {
                        height: fit-content;
                    }

                    .cal-time-label-column {
                        width: 50px;

                        .cal-time {
                            width: auto;
                        }

                    }

                    .cal-time-label-column .cal-hour .cal-hour-segment {
                        border-top: none;
                        border-bottom: none;
                    }

                    ._passed-event {
                        cursor: default;
                    }

                    .cal-event {
                        color: black;
                    }

                }
    
            }

        }

        mwl-calendar-day-view {

            .cal-day-view {

                .cal-time-events {
                    height: 100%;

                    ._passed-event {
                        cursor: default;
                    }

                    .cal-event {
                        color: black;
                    }

                    .cal-day-columns {
                        height: 100%;
                    }
                }

            }

        }

    }

}
