@import "src/theme/variables.scss";

:host {
	height: 100%;
	padding: 20px;
	background-color: white;
}

.owl-dt-popup-container {

    .owl-dt-calendar-cell-selected {
        background-color: $green;
    }
}

._workblock-component {
	height: 100%;

    ._date-time-picker-outer-container {
        margin-left: 30px;
    }

    ._date-time-picker-inner-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        ._time-picker {
            display: flex;
            flex-direction: column;
        }

        ._time-range-line {
            width: 10px;
            height: 2px;
            background-color: black;
        }

        .owl-dt-inline-container {
            box-shadow: none;
            width: auto;

            .owl-dt-timer {
                padding: 0;
                height: auto;

                .owl-dt-timer-box {
                    width: auto;
                    height: auto;
        
                    &:first-of-type {
                        margin-right: 5px;
                    }
                    &:last-of-type {
                        margin-left: 5px;
                    }

                    .owl-dt-timer-divider {
                        left: -9px;

                        &::before,
                        &::after {
                            width: 2px;
                            height: 2px;
                            border-radius: 100%;
                        }

                        &::before {
                            top: 45%;
                        }

                        &::after {
                            bottom: 45%;
                        }

                    }
                    
                    .owl-dt-timer-content {
                    
                        input {
                            font-size: 13px;
                        }
        
                    }
        
                }

            }

        }

    }

    ._time-picker-container {
        width: 150px;
	}
	
    ._recurring-container {
        width: 170px;
        margin-left: 30px;

        ._active {
            font-weight: 450;
        }

        ion-toggle {
            margin: 0 10px;
        }

	}

    ._interval-container {
        margin: 10px 30px 0 30px;
    }

    ._workblock-container {
        margin: 10px 30px 0 30px;

        form-textarea {
            display: block;
            margin-top: 20px;
        }
    }

    ._trainer-container {
        margin: 10px 30px 0 30px;

        form-trainer {
            ._search-results {
                top: 33px !important;
            }
        }
    }

}

._buttons-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
	padding-top: 30px;
	padding-bottom: 20px;

	._add-button {
		background-color: $green;
		font-weight: 500;
        margin-left: 10px;
	}

	._delete-button {
		background-color: $red;

		span {
			color: white;
		}
	}

}