@import "src/theme/variables.scss";

._base-calendar-desktop-component {

    ._calendar-headers {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        ._left-calendar-headers {
            display: flex;
            flex-direction: row;
            align-items: center;

            ._navigation-buttons {
                display: flex;
                flex-direction: row;
                align-items: center;
                background-color: white;
                border: 1px solid #D3D3D3;
                border-radius: 12px;
                height: fit-content;
                margin-right: 20px;
    
                ._navigation-button {
                    padding: 5px 20px;
    
                    &:hover {
                        cursor: pointer;
                    }
    
                    ion-icon {
                        font-size: 20px;
                        font-weight: bold;
                        margin-top: 5px;
                    }
    
                }

                ._today-navigation-button {
                    text-align: center;
                    border-right: 1px solid #D3D3D3;
                    border-left: 1px solid #D3D3D3;

                    ion-icon {
                        font-size: 12px;
                    }

                    p {
                        margin: 0;
                        font-size: 7px;
                    }
                }
    
            }
            
            ._view-selection {
                background-color: white;
                border: 1px solid #D3D3D3;
                border-radius: 12px;
                margin-right: 20px;
    
                ion-select {
                    padding: 9px 20px;
                }
			}

			._navigate-to-date {
				height: 41px;
			}

            ._current-daterange {
                margin-left: 20px;
            }

        }

        ._right-calendar-headers {
            display: flex;
            flex-direction: row;
			align-items: center;

			._add-button {
				background-color: $green;
				margin-right: 10px;
				font-weight: bold;
			} 
			
            ._refresh-button {
                background-color: $orange;
                color: white;
            }

        }

	}

    ._calendar {
        margin-top: 10px;

        ._event-icon {
            width: 15px;
            height: 15px;
            float: right;
            margin-top: 5px;
            margin-left: 5px;
            color: black;
        }

        mwl-calendar-month-view {

            .cal-month-view {

                .cal-header .cal-cell {
                    padding-top: 15px;
                    padding-bottom: 15px;
                }

                .cal-days {
                    border-left: none;
                    border-right: none;
                    border-bottom: none;

                    .ng-star-inserted:last-child {

                        .cal-cell-row {
                            border-bottom: none;
                        }

                    }

                } 

            }

        }

        mwl-calendar-week-view {

            .cal-week-view {
                border: none;
                height: 100%;
                overflow-y: auto;

                .cal-day-headers {
                    border-left: none;
                    border-right: none;
                    padding-left: 0;
                    margin-left: 70px;
    
                    .cal-header {
                        border-left: none;
                        border-right: none;
                        padding-top: 10px;
                        padding-bottom: 10px;

                        &:hover {
                            cursor: pointer;
                            background-color: #ededed !important;
                        }

                    }
                    .cal-today {
                        position: relative;
                        background-color: transparent;
                        color: $orange;
                    }
                    .cal-today::before {
                        content: "•";
                        color: $orange;
                        font-size: 25px;
                        position: absolute;
                        top: -7px;
                        left: 50%;
                        transform: translate(-50%, 0);
                    }
    
                }

				.cal-all-day-events {
					border-left: none;
					border-bottom-width: 1px;
					margin-right: 14px;
					border-right: none;
	
					.cal-event {
						color: black;
					}

					._event-icon {
						color: black;
					}
			
					& + .cal-time-events {
						height: calc(100% - 98px);
					}
	
				}

                .cal-time-events {
                    border: none;
                    height: calc(100% - 63px);
                    overflow-y: auto;
					overflow-x: hidden;

                    .cal-day-columns {
                        height: fit-content;
                    }

                    .cal-time-label-column .cal-hour .cal-hour-segment {
                        border-top: none;
                        border-bottom: none;
                    }

                    ._passed-event {
                        cursor: default;
                    }

                    .cal-event {
                        color: black;
                        white-space: normal;
                    }

                }
    
            }

        }

        mwl-calendar-day-view {

            .cal-day-view {

                .cal-time-events {
                    height: 100%;

                    ._passed-event {
                        cursor: default;
                    }

                    .cal-event {
                        color: black;
                        white-space: normal;
                    }

                    .cal-day-columns {
                        height: 100%;
                    }
                }

            }

        }

    }

}
