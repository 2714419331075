@import "src/theme/variables.scss";

._book-training-desktop-page {
    padding: 20px;
    display: flex;
    flex-direction: column;

    ._container {
        padding: 20px;
        background-color: white;
        border-radius: $border-radius;
        box-shadow: $box-shadow-light;
        width: 100%;

        &._hidden {
            opacity: 0.5;
        }

        ._title-container {
            margin-bottom: 30px;
        }

        ._small-title {
            margin: 20px 0;
        }

        ._missing-type {
            margin: 0;
            font-size: 15px;
        }

        &._training-details {

            ._types-container {
                display: flex;
                flex-direction: row;
                align-items: stretch;
                justify-content: space-evenly;

                ._type-container {
                    width: 40%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    padding: 20px;
                    background-color: #fdfdfd;
                    border: 1px solid #E0E0E0;
                    border-radius: $border-radius;

                    ._title {
                        margin: 0;
                    }

                    &._selected {
                        background-color: #F1F1F1;
                    }

                    &:hover {
                        cursor: pointer;
                    }

                    ion-icon {
                        font-size: 40px;
                        margin-right: 20px;
                    }

                }

            }

            ._extra-container {
                margin-top: 30px;

                ._template-and-language {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    flex-wrap: wrap;

                    form-input,
                    form-select,
                    form-select-training-template {
                        display: block;
                        width: 49%;
                    }

                    form-input {
                        margin-top: 20px;
                    }

                }

                ._small-title {
                    margin-top: 30px;
                }

                form-full-address {
                    display: block;
                    margin: 10px -10px 0 -10px;
                }

            }

        }

        &._client-data {
            position: relative;

            ._reset-company {
                position: absolute;
                right: 20px;
                top: 20px;
                background-color: $orange;
            }
        }

        &._closed-training-details {
            ._extra-container {
                margin-top: -20px;
            }
            ._contact-container {
                padding: 0 10px;

                ._contact-info {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    flex-wrap: wrap;
                }

                form-input {
                    display: block;
                    width: 49%;
                    margin-bottom: 20px;
                }

            }
        }

        &._planning {

            ._reservation-container {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-bottom: 20px;

                p {
                    margin: 0;
                }

                ion-checkbox {
                    margin-right: 10px;
                }

            }

            ._calendar-container {

                ._is-not-finalized {
                    margin: 0;
                    font-size: 14px;
                    color: grey;
                    margin-bottom: 10px;
                }

                ._calendar-events {
					display: flex;
					flex-direction: row;
					align-items: center;
					margin-bottom: 10px;

					._calendar-event {
						display: flex;
						flex-direction: row;
						align-items: center;
						border-radius: 10px;
						border: 1px solid $green;
						padding: 5px 10px;
						margin-right: 10px;

						&._selected {
							border: 1px solid #e1e1e1;
							background-color: #85C7F2;

							p {
								color: black !important;
							}

						}

						._event {
							display: flex;
							flex-direction: row;
							align-items: center;

							ion-icon {
								margin-left: 10px;

								&:hover {
									cursor: pointer;
								}

							}
						}

						._event-prefix {
							margin-right: 5px;
							font-weight: bolder;
						}

						p {
							margin: 0;
							font-size: 13px;
							display: inline;

							&._no-event,
							&._event-prefix {
								color: grey;
							}

						}

					}

				}

				._calendar-filter {
					margin-left: 20px;
				}

				._calendar-filters {
					margin-bottom: 10px;
					display: flex;
					flex-direction: row;

					._filter-name {
						color: grey;
						margin: 5px 0;
						text-transform: uppercase;
					}

				}

				._calendar {
					height: calc(100vh - 400px);

					&._has-filters {
						height: calc(100vh - 440px);
					}

					mwl-calendar-month-view {

						.cal-month-view {
							border-radius: $border-radius;
							border: 1px solid #e1e1e1;
						}

					}

					mwl-calendar-week-view {

						.cal-week-view {
							border-radius: $border-radius;
							border: 1px solid #e1e1e1;
							overflow-y: hidden;
						}

					}

				}


            }

            ._trainings-container {
                max-height: calc(100vh - 500px);
                overflow-y: auto;

                ._row {
                    margin: 1px auto;
                    border: 1px solid white;
                    padding-left: 5px;
                    border-radius: 15px;

                    &._offset {
                        background-color: #F1F1F1;
                        border: 1px solid #F1F1F1;
                    }

                    &._selected {
                        border: 1px solid #c0c0c0;
                    }

                }

                ._col {

                    ._info-button {
                        background-color: $orange;
                    }

                    ._select-button {
                        background-color: $green;
                    }

                }

            }

        }

        &._trainees {

            ._trainees-info {

                form-number,
                form-textarea {
                    display: block;
                    margin-bottom: 20px;
                }

            }

        }

        &._extra {

            form-textarea {
                display: block;
                margin-top: 20px;
            }

        }

        &._training-components {
            form-number,
            form-select {
                display: block;
                margin-bottom: 20px;
            }
        }

        &._invoicing-details {

            ._invoicing-details-input {
                display: flex;
                flex-direction: row;
                align-items: start;

                form-input,
                form-number {
                    display: block;
                    width: 33%;
                    margin-right: 10px;
                }

                ._price-container {
                    margin-right: 10px;
                    min-width: 258px;

                    form-number {
                        width: 100%;
                    }

                    ._cancellation-fee-container {
                        margin-top: 10px;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        
                        ion-label {
                            font-size: 14px;
                            color: grey;
                            margin-left: 5px;
                            flex-shrink: 10;
                        }
                    }
                }

            }

        }

        &._buttons {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: flex-end;

			._button {
				min-width: 130px;
				margin: 0 5px;

				&._previous {
					background-color: $orange;
				}

				&._cancel {
					background-color: $red;
					color: white;
					span {
						color: white;
					}
				}

				&._next {
					background-color: $green;
				}

				&._finish {
					background-color: $green;
					font-weight: bold;
				}

                &._reserve {
					background-color: $orange;
					font-weight: bold;
				}

				span {
					display: block;
					text-align: center;
					width: 100%;
					margin: 0;
				}

			}

		}

    }

}
