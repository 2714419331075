/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/* Required CSS for Angular Calendar Module */
@import "../node_modules/angular-calendar/css/angular-calendar.css";
@import "./app/modules/internal/gui/pages/calendar-page/desktop/calendar.desktop.page.scss";
@import "./app/modules/internal/gui/pages/calendar-page/mobile/calendar.mobile.page.scss";
@import "./app/modules/clients/gui/pages/book-training-page/desktop/book-training.desktop.page.scss";
@import "./app/modules/clients/gui/pages/book-training-page/mobile/book-training.mobile.page.scss";
@import "./app/modules/internal/gui/pages/book-training-page/desktop/book-training.desktop.page.scss";
@import "./app/modules/internal/gui/pages/book-training-page/mobile/book-training.mobile.page.scss";
@import "./app/modules/shared/gui/structural-components/base-calendar-component/desktop/base-calendar.desktop.component.scss";
@import "./app/modules/shared/gui/structural-components/base-calendar-component/mobile/base-calendar.mobile.component.scss";

/* Required CSS for date & time picker */
@import "../node_modules/@busacca/ng-pick-datetime/assets/style/picker.scss";
@import "./app/modules/shared/gui/structural-components/form-components/form-time-component/form-time.component.scss";
@import "./app/modules/internal/gui/components/workblock-component/workblock.component.scss";

/* Required CSS for select component */
@import "~@ng-select/ng-select/themes/default.theme.css";

/* Global style */
@import "src/theme/variables.scss";
@import url(https://fonts.googleapis.com/css?family=Open+Sans);

html, body, table {
    font-family: 'Open Sans',serif !important;
}

html, body {
    background-color: white !important;
    color: $dark-black;
}

html {
	padding-top:  constant(safe-area-inset-top) !important;
	padding-top: env(safe-area-inset-top) !important;
	padding-bottom: calc(constant(safe-area-inset-bottom)) !important;	
	padding-bottom: calc(env(safe-area-inset-bottom)) !important;	
}

body {
	position: relative;
}

._title {
    font-size: 20px;
    font-weight: 500;
    color: $dark-black;
    margin: 0;
}

._small-title {
    font-size: 17px;
    color: $dark-black;
    margin: 0;
}

._subtitle {
    font-size: 13px;
    color: grey;
    margin: 0;
}

._button {
    padding: 10px 15px;
    border-radius: 10px;
    box-shadow: 3px 3px 10px lightgrey;
    text-align: center;
    display: flex;
    flex-direction: row;
	align-items: center;
	justify-content: space-between;
	font-size: 15px;
	background-color: lightgrey;
	color: black;

	&:active {
		opacity: 0.9;
		box-shadow: none;
	}

    &:hover {
        cursor: pointer;
	}
	
    &[disabled] {
        background-color: lightgrey;

        &:hover {
            cursor: not-allowed;
        }
    }

    ion-icon {
		color: black;
		font-size: 20px;
		flex-shrink: 0;
    }

    span {
		margin-left: 10px;
		margin-right: 15px;
		flex-shrink: 10;
		color: black;
    }
}

* {
    outline: none;

    &:focus {
        outline: none;
    }
}

@media only screen and (min-width: 1024px) {
    // adds spacer to scoll on desktop page (add pages that need it)
    profile-page {
        margin-top: 20px;
    }
}

._navigatable {

	&:hover {
		cursor: pointer;
		opacity: 0.9;
	}

}

._drag-and-drop-container {

    /* Animate items as they're being sorted. */
    .cdk-drop-list-dragging .cdk-drag {
        transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
    }
    
    /* Animate an item that has been dropped. */
    .cdk-drag-animating {
        transition: transform 300ms cubic-bezier(0, 0, 0.2, 1);
    }

    ._drag-and-drop-placeholder {
        border: 2px dashed #E0E0E0;
        padding: 20px;
        width: 100%;
        border-radius: $border-radius;
        background-color: #F1F1F1;
        transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
    }

}

._language-option-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    ion-icon {
        margin-right: 8px;
    }

}



/* Print rules */

@media print {
	body.backdrop-no-scroll {
		overflow: visible;
	}
}

/* Ionic component overrides */

ion-select:not(.legacy-select) {
    min-height: inherit;
}

ion-select{
    --highlight-color: inherit;
}

ion-content {
    --ion-background-color: transparent;
}

ion-popover {
	--width: fit-content !important;

    &::part(content) {
        top: 50% !important;
        left: 50% !important;
        transform: translate(-50%, -50%) !important;
    }

}

ion-loading._loading-indicator {
	--background: white;
	--spinner-color: #4B4237;
	color: #4B4237;

	.loading-wrapper {
		border-radius: 10px;

		.loading-content {
			font-size: 16px;
		}

	}

}

ion-searchbar {

    .searchbar-input-container  {
        border: 2px solid #f0f0f0;
        border-radius: $border-radius;
        --box-shadow: none;

        input {
            border-radius: $border-radius !important;
        }
    }

} 


ion-modal {

    --background:  #F1F1F1 !important;

    @media only screen and (min-width: 1024px) {
        --border-radius: 7px !important;
    }

    &.auto-height {
        
        &.bottom {
            align-items: flex-end;
        }
    
		--height: auto;

		@media print {
			--height: 100%;
		}
        
        @media only screen and (max-width: 1024px) {
            --height: 100%;
        }
    
        .ion-page {
            position: relative;
            display: block;
			contain: content;
			height: 100%;
    
            .inner-content {
                max-height: 90vh;
				overflow: auto;
				
				@media only screen and (max-width: 1024px) {
					max-height: 100%;
					height: 100%;
				}

				@media print {
					overflow: visible;
				}
            }
        }
    }
    
}

// Applies a text wrap on ion options
.alert-tappable.alert-radio,
.alert-tappable.alert-checkbox {
    height: auto;
    contain: content;
}

.alert-radio-label.sc-ion-alert-md,
.alert-radio-label.sc-ion-alert-ios,
.alert-checkbox-label.sc-ion-alert-md,
.alert-checkbox-label.sc-ion-alert-ios {
    white-space: normal;
}

.sc-ion-alert-md-h {
    --max-width: 90vw;
}

ion-alert {

	._alert-button {
		color: black;
	}
}

ion-toast {

    &._saved-toast {
        --background: #78BC61;
        --border-radius: 10px;
        --width: fit-content;
        --color: black;
		--ion-safe-area-bottom: 20px;
		--max-width: 90%;
        font-weight: 500 !important;
	}
	
	&._error-toast {
        --background: #EB4B24;
        --border-radius: 10px;
        --width: fit-content;
        --color: white;
		--ion-safe-area-bottom: 20px;
		--max-width: 90%;
		--button-color: white;
		font-weight: 500 !important;
		
		.toast-wrapper {
			max-width: 90%;
		}

    }

}

.plt-mobile.plt-mobileweb ion-toast {
	
	&._saved-toast {
		--max-width: 90%;
	}
	
	&._error-toast {
		--max-width: 90%;
    }
}

ion-grid {
	--ion-grid-columns: 15;
}

ion-checkbox.checkbox-disabled {
	opacity: 1;
}