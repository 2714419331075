@import "src/theme/variables.scss";

._calendar-desktop-page {
    padding: 20px;
	position: relative;

    ._right-calendar-headers {
		display: flex;
		flex-direction: row;
		align-items: center;
		position: absolute;
		top: 23px;
		right: 30px;

		&._has-filters {
			top: 75px;
		}

		._actions-container {
			margin-right: 10px;
			position: relative;

			._add-button {
				background-color: $green;
	
				ion-icon {
					font-weight: bold;
				}
			} 

			._actions {
				position: absolute;
				top: 45px;
				right: 0;
				z-index: 100000;
				background-color: white;
				box-shadow: $box-shadow-dark;
				border-radius: 10px;

				&._hidden {
					display: none;
				}
				
				._action {
					padding: 10px 20px;
					border-bottom: 1px solid lightgrey;
					display: flex;
					flex-direction: row;
					align-items: center;	

					&:hover {
						cursor: pointer;
						background-color: #f1F1F1;
					}

					&:first-child {
						border-radius: 10px 10px 0 0;
					}

					&:last-child {
						border: none;
						border-radius: 0 0 10px 10px;
					}

					ion-icon {
						margin-right: 10px;
						color: $green;
						font-size: 25px;
					}
					
					p {
						margin: 0;
						white-space: nowrap;
					}

				}

			}
	
		}

		
		._cancel-button {
			background-color: $red;
			margin-right: 10px;
			color: white;
			font-weight: normal;
		}
		
		._refresh-button {
			background-color: $orange;
			color: white;
		}

	}
	
	._calendar-filters {
		margin-bottom: 10px;
		display: flex;
		flex-direction: row;

		> * {
			margin-right: 10px;
		}

		._data-filter-component {
			height: 100%;
		}

        ._filter-name {
            color: grey;
            margin: 5px 0;
            text-transform: uppercase;
        }

		._selected ._input {
			padding: 0 10px;
		}
        
	}

	._calendar {
		height: calc(100vh - 184px);
		
		&._has-filters {
			height: calc(100vh - 224px);
		}
        
        mwl-calendar-month-view {

            .cal-month-view {
                border-radius: $border-radius;
                box-shadow: $box-shadow-light;
            }

        }

        mwl-calendar-week-view {

            .cal-week-view {
                border-radius: $border-radius;
                box-shadow: $box-shadow-light;    
            }

        }

    }

}
