@import "src/theme/variables.scss";

:host {
	height: 100%;
}

._book-training-mobile-page {
   

}
