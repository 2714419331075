@import "src/theme/variables.scss";

._book-training-desktop-page {
    padding: 20px;
	display: flex;
	flex-direction: row;
	align-items: stretch;

    ._container {
		background-color: white;
		box-shadow: $box-shadow-light;
		border-radius: $border-radius;
		margin-bottom: 20px;
		display: flex;
		flex-direction: column;
	}

	._progress-indicator {
		height: calc(100vh - 125px);
		max-width: 25vw;
		flex-shrink: 10;
	}

	._action {
		height: calc(100vh - 125px);
		overflow-y: auto;
		margin-left: 20px;
		padding: 30px;
		width: 100%;
		position: relative;

		._action-container {
			flex-grow: 10;
			margin-bottom: 30px;

			._intro {
				margin-bottom: 30px;
			}

			._container-subtitle {
				font-size: 14px;
				margin-bottom: 3px;
			}

			&._type {

				._types-container {
					display: flex;
					flex-direction: row;
					align-items: stretch;
					justify-content: space-evenly;

					._type-container {
						width: 40%;
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: center;
						padding: 20px;
						background-color: #fdfdfd;
    					border: 1px solid #E0E0E0;
						border-radius: $border-radius;

						&._selected {
							background-color: #F1F1F1;
						}

						&:hover {
							cursor: pointer;
						}

						._subtitle {
							margin-top: 10px;
							text-align: center;
						}

						ion-icon {
							font-size: 60px;
							margin-bottom: 20px;
						}

					}

				}

				._extra-container {
					margin-top: 30px;

					._template-and-language {
						display: flex;
						flex-direction: row;
						align-items: center;
						justify-content: space-between;

						form-select,
						form-select-training-template {
							display: block;
							width: 49%;
						}

					}

					._small-title {
						margin-top: 30px;
					}

					form-full-address {
						display: block;
						margin: 10px -10px 0 -10px;
					}

				}


			}

			&._trainings {

				._intro {
					margin-bottom: 20px;
				}
				
				/* FOR CALENDAR */

				._calendar-events {
					display: flex;
					flex-direction: row;
					align-items: center;
					margin-bottom: 10px;

					._calendar-event {
						display: flex;
						flex-direction: row;
						align-items: center;
						border-radius: 10px;
						border: 1px solid $green;
						padding: 5px 10px;
						margin-right: 10px;

						&._selected {
							border: 1px solid #e1e1e1;
							background-color: #85C7F2;

							p {
								color: black !important;
							}

						}

						._event {
							display: flex;
							flex-direction: row;
							align-items: center; 

							ion-icon {
								margin-left: 10px;

								&:hover {
									cursor: pointer;
								}

							}
						}

						._event-prefix {
							margin-right: 5px;
							font-weight: bolder;
						}

						p {
							margin: 0;
							font-size: 13px;
							display: inline;

							&._no-event,
							&._event-prefix {
								color: grey;
							}

						}	

					}

				}

				._calendar-filters {
					margin-bottom: 10px;
					display: flex;
					flex-direction: row;
			
					._filter-name {
						color: grey;
						margin: 5px 0;
						text-transform: uppercase;
					}
					
				}
			
				._calendar {
					height: calc(100vh - 400px);
					
					&._has-filters {
						height: calc(100vh - 440px);
					}
					
					mwl-calendar-month-view {
			
						.cal-month-view {
							border-radius: $border-radius;
							border: 1px solid #e1e1e1;
						}
			
					}
			
					mwl-calendar-week-view {
			
						.cal-week-view {
							border-radius: $border-radius;
							border: 1px solid #e1e1e1;
							overflow-y: hidden;
						}
			
					}
			
				}

				/* FOR LIST */

				ion-content {
					height: calc(100vh - 310px);

					._row {
						margin: 1px auto;
						border: 1px solid white;
						padding-left: 5px;
						border-radius: 15px;
				
						&._offset {
							background-color: #F1F1F1;
							border: 1px solid #F1F1F1;
						}

						&._selected {
							border: 1px solid #c0c0c0;
						}
						
					}
				
					._col {

						._info-button {
							background-color: $orange;
						}

						._select-button {
							background-color: $green;
						}
				
					}

				}

			}

			&._trainees {

				._trainees-info {

					form-number,
					form-textarea {
						display: block;
						margin-bottom: 20px;
					}

				}

				._contact-container {

					._contact-info {
						display: flex;
						flex-direction: row;
						justify-content: space-between;
						flex-wrap: wrap;
					}
	
					form-input {
						display: block;
						width: 49%;
						margin-bottom: 20px;
					}
	
				}

				._invoicing-details-container {
					margin-top: 20px;
				}

			}

			&._terms-and-conditions {

				._terms-and-conditions-container {
					display: flex;
					flex-direction: row;
					align-items: center;
					margin-top: 20px;
					margin-bottom: 10px;
					margin-left: 10px;
					flex-shrink: 0;
		
					ion-label {
						margin-left: 10px;
						flex-shrink: 10;
					}
		
				}
			}

			&._confirmation {

				._confirmation-container {
					display: flex;
					flex-direction: row;
					border-radius: $border-radius;
					border: 2px solid #d8d8d8;
					padding: 30px;
					margin: 30px 0;

					._map-container {
						width: 400px;
						min-width: 300px;
						margin-right: 30px;
						min-height: 250px;
						border: 1px solid #D3D3D3;
						border-radius: $border-radius;
						overflow: hidden;
					}

					._training-details {
						max-height: calc(100vh - 310px);
						overflow-y: auto;
						flex-grow: 10;
					}

				}

				._booking-disclaimer-container {
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					margin-top: 10px;

					._booking-disclaimer {
						display: flex;
						flex-direction: row;
						align-items: center;

						ion-icon {
							color: $orange;
							font-size: 30px;
							margin-right: 10px;
						}
	
						p {
							font-size: 20px;
							margin: 0;
							flex-shrink: 10;
						}
					}

					._finish {
						margin-top: 20px;
						background-color: $green;
						font-weight: bold;

						span {
							font-size: 20px;
						}
					}

				}

				._booked-confirmation-container {
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					height: calc(100% - 60px);

					ion-icon {
						color: $green;
						font-size: 200px;
					}

					p {
						font-size: 25px;
					}

				}

			}

		}

		._buttons {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: flex-end;
	
			._button {
				min-width: 130px;
				margin: 0 5px;
	
				&._previous {
					background-color: $orange;
				}  
	
				&._cancel {
					background-color: $red;
					color: white;
					span {
						color: white;
					}
				}  
	
				&._next {
					background-color: $green;
				} 
	
				&._finish {
					background-color: $green;
					font-weight: bold;
				} 
	
				span {
					display: block;
					text-align: center;
					width: 100%;
					margin: 0;
				}
	
			} 
	
		}

	}

}